/* eslint-disable @typescript-eslint/indent */
import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { initialize, LDClient, LDOptions } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, catchError, filter, finalize, from, map, Observable, of, switchMap } from 'rxjs';

import { LDContext } from '@@core/models/auth/auth-model';


enum ENVIRONMENT {
	PROD = 'prod',
	STAGING = 'staging',
	DEV = 'dev',
	CI = 'ci',
}

const LD_ENV_DATA = {
	[ENVIRONMENT.PROD]: {
		key: '64dc79dd226fb714746dadb5',
		env: ENVIRONMENT.PROD,
	},
	[ENVIRONMENT.STAGING]: {
		key: '64dc79fa71eaf013275d0d05',
		env: ENVIRONMENT.STAGING,
	},
	[ENVIRONMENT.DEV]: {
		key: '64dc7a0b23835c13515c0783',
		env: ENVIRONMENT.DEV,
	},
	[ENVIRONMENT.CI]: {
		key: '64dc7a39b55d781370f95417',
		env: ENVIRONMENT.CI,
	},
};

export enum LDFeatureKey {
	MANUAL_TEST_RUNNER = 'MANUAL_TEST_RUNNER',
	MANUAL_TEST_EXTENSION = 'MANUAL_TEST_EXTENSION',
	TIA_MODULES_TAB = 'TIA_MODULES_TAB',
	COVERAGE_TYPE_SELECTOR = 'COVERAGE_TYPE_SELECTOR',
	COCKPIT_FOOTPRINTS = 'COCKPIT_FOOTPRINTS',
	PUBLIC_API = 'PUBLIC_API',
	QRD = 'QRD',
	PERMISSIONS = 'PERMISSIONS',
	CUSTOM_SUPPORT = 'CUSTOM_SUPPORT',
	SLACK_INTEGRATION = 'SLACK_INTEGRATION',
	CI_MONITOR = 'CI_MONITOR',
	TIA_REF_BUILD_SUPPORT = 'TIA_REF_BUILD_SUPPORT',
	AGENT_VERSIONS = 'AGENT_VERSIONS',
	LABS = 'LABS',
	DASHBOARD_BACKEND_SEARCH = 'DASHBOARD_BACKEND_SEARCH',
	USAGE_ANALYTICS = 'USAGE_ANALYTICS',
	USER_PILOT = 'USER_PILOT',
	AGENT_JSON_API_ON = 'AGENT_JSON_API_ON',
	DISPLAY_COCKPIT_SETTINGS = 'DISPLAY_COCKPIT_SETTINGS',
	HOTJAR = 'HOTJAR',
	RECOMMENDED_TEST_LIST = 'RECOMMENDED_TEST_LIST',
	EXECUTIVE_SUMMARY_REPORT = 'EXECUTIVE_SUMMARY_REPORT',
	UNIFIED_SETTINGS = 'UNIFIED_SETTINGS',
	QUALITY_ANALYTICS_REPORTS_NOTIFICATIONS = 'QUALITY_ANALYTICS_REPORTS_NOTIFICATIONS',
	PROOF_OF_TESTING_REPORT = 'PROOF_OF_TESTING_REPORT',
	CODE_SCOPE = 'CODE_SCOPE',
	TROUBLESHOOTING = 'TROUBLESHOOTING',
	LOCK_MANUAL_TEST_STAGE_NAME = 'LOCK_MANUAL_TEST_STAGE_NAME',
	FTV = 'FTV',
	IGNORE_METHOD = 'IGNORE_METHOD',
	CUSTOM_TABS = 'CUSTOM_TABS',
	LIVE_LABS_MONITOR = 'LIVE_LABS_MONITOR',
	TIA_NEW_OVERVIEW = 'TIA_NEW_OVERVIEW',
	TEST_MANAGEMENT_TAB_VISIBLE = 'TEST_MANAGEMENT_TAB_VISIBLE',
	DASHBOARD_V2 = 'DASHBOARD_V2',
	LINE_COVERAGE = 'LINE_COVERAGE',
	CALCULATE_COVERAGE = 'CALCULATE_COVERAGE',
	TIA_SETTINGS_DIALOG_V2 = 'TIA_SETTINGS_DIALOG_V2',
	DASHBOARD = 'DASHBOARD',
	TIA_TIME_LINE = 'TIA_TIME_LINE',
	AUT_TAB = 'AUT_TAB',
	TIA_MODE = 'TIA_MODE',
	TGA_FOR_BRANCHING_OUT = 'TGA_FOR_BRANCHING_OUT',
	CUSTOMER_WEB_APP_CONFIG = 'CUSTOMER_WEB_APP_CONFIG',
	COVERAGE_INTEGRATION_INDICATOR = 'COVERAGE_INTEGRATION_INDICATOR',
	TSR_DASHBOARD = 'TSR_DASHBOARD',
	TSR_TIA_TAB = 'TSR_TIA_TAB',
	DASHBOARD_VIEWS = 'DASHBOARD_VIEWS',
	LAB_MAPPING_PAGE = 'LAB_MAPPING_PAGE'
}

@Injectable({
	providedIn: 'root'
})
export class LaunchDarklyService {
	#ldInitialized$ = new BehaviorSubject<boolean>(false);
	#initializationProcess: Observable<boolean> = null;
	#ldClient: LDClient;
	readonly ldInitializedSignal$ = toSignal(this.#ldInitialized$);


	get ldInitialized$(): Observable<boolean> {
		return this.#ldInitialized$.asObservable();
	}

	initialize(context: LDContext, hash: string): Observable<boolean> {
		if (this.#ldInitialized$.value) {
			return of(true);
		}
		if (this.#initializationProcess) {
			return this.#initializationProcess;
		}
		const workingEnv: ENVIRONMENT = this.#getEnv(context.environmentName);
		const options: LDOptions = this.#getOptions(hash, workingEnv);
		const clientId: string = LD_ENV_DATA[workingEnv].key;
		this.#ldClient = initialize(clientId, context, this.#isLocalHost() ? { hash } : options);

		this.#initializationProcess = from(this.#ldClient.waitForInitialization(5)).pipe(
			map(() => {
				this.notifyLdInitialized(true);
				return true;
			}),
			catchError(() => {
				this.notifyLdInitialized(false);
				return of(false);
			}),
			finalize(() => this.#initializationProcess = null)
		);
		return this.#initializationProcess;
	}

	notifyLdInitialized(value: boolean): void {
		this.#ldInitialized$.next(value);
	}

	getFeatureFlagValue(flagKey: LDFeatureKey): Observable<boolean> {
		return new Observable(observer => {
			try {
				const value = this.#ldClient.variation(flagKey) as boolean;
				observer.next(value);
				observer.complete();
			} catch (error) {
				console.error(`Error getting feature flag value for: ${ flagKey }`, error);
				observer.error(error);
			}
		});
	}

	getJsonObjectForKey(flagKey: LDFeatureKey): Observable<Record<string, any>> {
		return this.ldInitialized$
			.pipe(
				filter((isInitialized) => !!isInitialized),
				switchMap(() => of(this.#ldClient.variation(flagKey, {}) as Observable<Record<string, any>>)),
			);
	}

	subscribeToFlagChanges(flagKey: LDFeatureKey, callback: (newValue: boolean) => void): void {
		this.#ldClient.on(`change:${ flagKey }`, callback);
	}

	unsubscribeFromFlagChangeListener(flagKey: LDFeatureKey, callback: (newValue: boolean) => void): void {
		this.#ldClient.off(`change:${ flagKey }`, callback);
	}

	terminateClient(): void {
		void this.#ldClient?.close();
		this.#ldClient = null;
	}

	shutdown(): void {
		this.terminateClient();
		this.notifyLdInitialized(false);
	}

	#getOptions(hash: string, LDEnv: ENVIRONMENT): LDOptions {
		const hostname = window.location.hostname;
		const path = LDEnv === ENVIRONMENT.PROD || this.#isStaging() || this.#isIntegration() ? 'ld' : '';
		const url = `https://${ hostname }/${ path }`;

		const options: LDOptions = {
			baseUrl: url,
			eventsUrl: url,
			streamUrl: url,
			useReport: false,
			hash,
			sendLDHeaders: false,
			requestHeaderTransform: (headers) => ({
				...headers,
				'X-SL-RELAY-REDIRECT': true,
			})
		};
		return options;
	}


	#isLocalHost(): boolean {
		const url = window.location.hostname;
		return url.includes('localhost') || url.includes('127.0.0.1');
	}

	#isStaging(): boolean {
		const url = window.location.hostname;
		return url.includes('dev-staging') || url.includes('dev-staging-gw');
	}

	#isIntegration(): boolean {
		const url = window.location.hostname;
		return url.includes('dev-integration') || url.includes('dev-integration-gw');
	}

	#getEnv(env: string): ENVIRONMENT {
		const match = env.match(/DEV-(integ-|staging|integration|[^/]+)/);

		if (!match) {
			return ENVIRONMENT.PROD;
		}

		if (match[1].startsWith('integ-')) { return ENVIRONMENT.CI; }
		else if (match[1] === 'staging') { return ENVIRONMENT.STAGING; }
		else { return ENVIRONMENT.DEV; }
	}
}
